import React from "react";
import parse from "html-react-parser";
import slugify from "slugify";

const BlogSingle = ({ data }) => {
  // console.log("BlogSingle", data);
  const url = `${process.env.LANDING_URL}/blog-post/${slugify(data.Title, {
    lower: true,
    remove: /[*+~.,\[\](){}'"!?:@]/g,
  })}-${data.strapi_id}`;
  return (
    <div>
      <div className="blog-details__main">
        {data.Image && (
          <div className="blog-details__image">
            <img
              src={`${process.env.GATSBY_API_URL}${
                data.Image?.formats?.large?.url ?? data.Image?.url
              }`}
              alt={data.Title}
            />
          </div>
        )}
        <div className="blog-details__content">
          <div className="blog-one__meta">
            {data.Date && (
              <a href="#none">
                <i className="far fa-clock"></i> {data.Date}
              </a>
            )}
            {/* <a href="#none">
              <i className="far fa-comments"></i> 2 comments
            </a> */}
          </div>
          <h3>{data.Title}</h3>
          <div>{parse(data.Lead.data.Lead)}</div>
          <div>{parse(data.Content.data.Content)}</div>
        </div>
        <div className="blog-details__meta">
          {data.tags.length > 0 && (
            <div className="blog-details__tags">
              <span>Címkék: </span>
              {data.tags.map((tag, index) => (
                <React.Fragment key={index}>
                  <a
                    href={`/blog/tags/${slugify(tag.Name, {
                      lower: true,
                      remove: /[*+~.,\[\](){}'"!?:@]/g,
                    })}-${tag.strapi_id}`}
                  >
                    {tag.Name}
                  </a>
                  {index !== data.tags.length - 1 && ", "}
                </React.Fragment>
              ))}
            </div>
          )}

          <div className="blog-details__share">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                url
              )}`}
              target="_blank"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
            <a
              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                url
              )}`}
              target="_blank"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href={`ttps://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                url
              )}`}
              target="_blank"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href={`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
                url
              )}&media=&description=`}
              target="_blank"
            >
              <i className="fab fa-pinterest-p"></i>
            </a>
          </div>
        </div>
      </div>
      {/* <div className="blog-author">
        <div className="blog-author__image">
          <img src={AuthorImage} alt="awesome post" />
        </div>
        <div className="blog-author__content">
          <h3>Christine Eve </h3>
          <p>
            Lorem Ipsum is simply dummy text of the rinting and typesetting been
            the industry dummy text ever sincer condimentum purus.
          </p>
        </div>
      </div> */}
      {/* <div className="comment-one">
        <h3 className="comment-one__block-title">2 Comments</h3>
        <div className="comment-one__single">
          <div className="comment-one__image">
            <img src={CommentImage1} alt="awesome post" />
          </div>
          <div className="comment-one__content">
            <h3>Eugenia Floyd</h3>
            <p className="comment-one__date">
              20 Mar, 2020 <span>.</span> 4:00 pm
            </p>
            <p>
              Lorem Ipsum is simply dummy free text of the available printing
              and typesetting been the industry standard dummy text ever sincer
              condimentum purus.
            </p>
          </div>
          <a href="#none" className="thm-btn comment-one__btn">
            <span>Reply</span>
          </a>
        </div>
        <div className="comment-one__single">
          <div className="comment-one__image">
            <img src={CommentImage2} alt="awesome post" />
          </div>
          <div className="comment-one__content">
            <h3>Nellie Hanson</h3>
            <p className="comment-one__date">
              20 Mar, 2020 <span>.</span> 4:00 pm
            </p>
            <p>
              Lorem Ipsum is simply dummy free text of the available printing
              and typesetting been the industry standard dummy text ever sincer
              condimentum purus.
            </p>
          </div>
          <a href="#none" className="thm-btn comment-one__btn">
            <span>Reply</span>
          </a>
        </div>
      </div>
      <div className="comment-form">
        <h3 className="comment-one__block-title">Leave a Comment</h3>
        <form className="contact-form-validated contact-one__form">
          <div className="row">
            <div className="col-lg-6">
              <input type="text" placeholder="Name" name="name" />
            </div>
            <div className="col-lg-6">
              <input type="text" placeholder="Email Address" name="email" />
            </div>
            <div className="col-lg-6">
              <input type="text" placeholder="Website" name="website" />
            </div>
            <div className="col-lg-6">
              <input type="text" placeholder="Subject" name="subject" />
            </div>
            <div className="col-lg-12">
              <textarea placeholder="Write Message" name="message"></textarea>
            </div>
            <div className="col-lg-12 text-left">
              <button type="submit" className="thm-btn contact-one__btn">
                <span>Submit Comment</span>
              </button>
            </div>
          </div>
        </form>
      </div> */}
    </div>
  );
};

export default BlogSingle;
