import React from "react";
import slugify from "slugify";

const BlogSidebar = ({ tags, months, latestPosts }) => {
  // console.log("BlogSidebar", tags, months, latestPosts);
  return (
    <div className="sidebar">
      {/* <div className="sidebar__single sidebar__search">
        <form action="#" className="sidebar__search-form">
          <input type="text" name="search" placeholder="Search here..." />
          <button type="submit">
            <i className="fa fa-search"></i>
          </button>
        </form>
      </div> */}
      {latestPosts?.length > 0 && (
        <div className="sidebar__single sidebar__post">
          <h3 className="sidebar__title">Legfrissebb bejegyzések</h3>
          <div className="sidebar__post-wrap">
            {latestPosts.map((post, index) => (
              <div key={index} className="sidebar__post__single">
                {post.Image && (
                  <div className="sidebar__post-image">
                    <a
                      href={`/blog-post/${slugify(post.Title, {
                        lower: true,
                        remove: /[*+~.,\[\](){}'"!?:@]/g,
                      })}-${post.strapi_id}`}
                      className="inner-block"
                    >
                      <img
                        src={`${process.env.GATSBY_API_URL}${
                          post.Image?.formats?.small?.url ?? post.Image?.url
                        }`}
                        alt={post.Title}
                      />
                    </a>
                  </div>
                )}
                <div className="sidebar__post-content">
                  <h4 className="sidebar__post-title">
                    <a
                      href={`/blog-post/${slugify(post.Title, {
                        lower: true,
                        remove: /[*+~.,\[\](){}'"!?:@]/g,
                      })}-${post.strapi_id}`}
                    >
                      {post.Title}
                    </a>
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {months && (
        <div className="sidebar__single sidebar__category">
          <h3 className="sidebar__title">Archívum</h3>
          <ul className="sidebar__category-list">
            {Object.keys(months).map((month) => (
              <React.Fragment key={month}>
                <li className="sidebar__category-list-item">
                  <a
                    href={`/blog/archivum/${month}`}
                    className="d-flex justify-content-between align-items-center"
                  >
                    {months[month].Name}
                    <span className="badge badge-primary badge-pill">
                      {months[month].count}
                    </span>
                  </a>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
      {tags && (
        <div className="sidebar__single sidebar__tags">
          <h3 className="sidebar__title">Címkék</h3>
          <ul className="sidebar__category-list">
            {tags.map((tag) => (
              <React.Fragment key={tag.tag}>
                <li className="sidebar__category-list-item">
                  <a
                    href={`/blog/tags/${tag.tag}`}
                    className="d-flex justify-content-between align-items-center"
                  >
                    {tag.Name}
                    <span className="badge badge-primary badge-pill">
                      {tag.count}
                    </span>
                  </a>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BlogSidebar;
